import React, {useState} from 'react';
import './DiggingGame.css';

var perlin = require('perlin-noise');

enum Tool {
    Pick, Hammer,
}

interface Reward {
    name: string,
    sprites: string[][],
    tiles: number[][],
    value: number,
}


const Rewards = [
    {
        name: "smallgreen",
        sprites: [["gems/gs1.png", "gems/gs2.png"], ["gems/gs3.png", "gems/gs4.png"]],
        tiles: [[1, 1], [1, 1]],
        value: 10,
    },
    {
        name: "bigred",
        sprites: [["gems/rb1.png", "gems/rb2.png", "gems/rb3.png"], ["gems/rb4.png", "gems/rb5.png", "gems/rb6.png"], ["gems/rb7.png", "gems/rb8.png", "gems/rb9.png"]],
        tiles: [[1, 1, 1], [1, 1, 1], [1, 1, 1]],
        value: 10,
    }
]


const ToolData = {
    [Tool.Pick]: {
        name: "Pick", image: "/img/pick.png", durabilityLoss: 1, hitArea: [[0, 1, 0], [1, 2, 1], [0, 1, 0]],
    },

    [Tool.Hammer]: {
        name: "Hammer", image: "/img/hammer.png", durabilityLoss: 2, hitArea: [[1, 2, 1], [2, 2, 2], [1, 2, 1]],
    }
}

function DiggingGame() {

    const boardHeight = 10;
    const boardWidth = 13;
    const allowedStartingTiles = [2, 4, 6]

    const generateEmptyBoard = () => Array(boardHeight).fill(Array(boardWidth).fill(6));

    const deepCopyBoard = (board: number[][]) => {
        return board.map(function (arr) {
            return arr.slice();
        });
    }

    const generateNewBoard = () => {
        let newBoard = deepCopyBoard(generateEmptyBoard());

        let options = {
            octaveCount: 2, amplitude: 1, persistence: 0.1,
        }
        let noise = perlin.generatePerlinNoise(boardHeight, boardWidth, options)

        let noiseSum = noise.reduce((a: number, b: number) => a + b, 0);
        let expectedSum = boardWidth * boardHeight * 0.65;
        noise = noise.map((n: number) => Math.min(0.999, n * (expectedSum / noiseSum)));

        for (let i = 0; i < boardHeight; i++) {
            for (let j = 0; j < boardWidth; j++) {
                newBoard[i][j] = allowedStartingTiles[Math.floor(noise[(i * boardHeight) + j] * allowedStartingTiles.length)];
            }
        }

        return newBoard;
    }

    interface RewardState {
        rewards: RewardEntry[],
        rewardBoard: (string | null)[][],
    }

    interface RewardEntry {
        reward: Reward,
        top_left: Coordinate,
        tiles: Coordinate[],
    }

    interface Coordinate {
        x: number,
        y: number,
    }


    const generateNewRewardState = (): RewardState => {
        let rewardEntries: RewardEntry[] = [];

        let all_tiles: Coordinate[] = [];

        for (let i = 0; i < 3; i++) {
            let reward = Rewards[Math.floor(Math.random() * Rewards.length)];
            let rewardHeight = reward.tiles.length;
            let rewardWidth = reward.tiles[0].length;

            while (true) {
                let x = Math.floor(Math.random() * (boardWidth - rewardWidth));
                let y = Math.floor(Math.random() * (boardHeight - rewardHeight));

                let tiles: Coordinate[] = [];
                let valid = true;

                for (let j = 0; j < rewardHeight; j++) {
                    for (let k = 0; k < rewardWidth; k++) {
                        let test_tile = {x: x + k, y: y + j};
                        if (all_tiles.some((tile) => tile.x === test_tile.x && tile.y === test_tile.y)) {
                            valid = false;
                            break;
                        } else {
                            tiles.push(test_tile);
                        }
                    }
                    if (!valid) {
                        break;
                    }
                }

                if (valid) {
                    all_tiles.push(...tiles);
                    rewardEntries.push({
                        reward: reward, top_left: {x: x, y: y}, tiles: tiles,
                    });
                    break;
                }
            }
        }

        let newRewardBoard: (string | null)[][] = Array(boardHeight).fill(Array(boardWidth).fill(null));
        newRewardBoard = newRewardBoard.map(function (arr) {
            return arr.slice();
        });

        for (let rewardi = 0; rewardi < rewardEntries.length; rewardi++) {
            console.log('for reward', rewardi);

            let reward = rewardEntries[rewardi].reward;
            let top_left = rewardEntries[rewardi].top_left;

            for (let ry = 0; ry < reward.tiles.length; ry++) {
                for (let rx = 0; rx < reward.tiles[ry].length; rx++) {
                    console.log('setting tile', top_left.y + ry, top_left.x + rx);
                    newRewardBoard[top_left.y + ry][top_left.x + rx] = reward.sprites[ry][rx];
                }
            }
        }

        return {rewards: rewardEntries, rewardBoard: newRewardBoard};
    }



    const [board, setBoard] = useState(() => generateNewBoard());
    const [rewardState, setRewardState] = useState(() => generateNewRewardState());

    const [tool, setTool] = useState(Tool.Pick);
    const [durability, setDurability] = useState(50);


    const resetBoard = () => {
        setBoard(generateNewBoard());
        setRewardState(generateNewRewardState());
        setDurability(50);
    }

    const dig = (x: number, y: number) => {
        if (durability <= 0) {
            return;
        }

        console.log(`Digging at ${x}, ${y}`);

        const newBoard = deepCopyBoard(board);

        let toolData = ToolData[tool];

        const hits = toolData.hitArea;
        for (let i = 0; i < hits.length; i++) {
            for (let j = 0; j < hits[i].length; j++) {
                let hx = x + i - 1;
                let hy = y + j - 1;
                if (0 <= hx && hx < boardWidth && 0 <= hy && hy < boardHeight) {
                    newBoard[hy][hx] = Math.max(0, newBoard[hy][hx] - hits[i][j]);
                }
            }
        }

        setDurability(durability - toolData.durabilityLoss);

        setBoard(newBoard);
    }

    console.log(board)
    console.log(rewardState)

    const CheckRewards = () => {

        for (let rewardi = 0; rewardi < rewardState.rewards.length; rewardi++) {
            let tiles = rewardState.rewards[rewardi].tiles;

            for (let i = 0; i < tiles.length; i++) {
                let tile = tiles[i];
                if (board[tile.y][tile.x] !== 0) {
                    console.log('found a tile that is not 0 for reward', tile.x, tile.y, rewardState.rewards[rewardi])
                    return false;
                }
            }
        }

        return true;
    }

    let foundAllRewards = CheckRewards();

    let popup = null;

    if (foundAllRewards) {
        popup = <DinosaurPopup message={"Muy bien! You have found all the treasures!"}  />
    } else if (durability <= 0) {
        popup = <DinosaurPopup message={"Do better."}  />
    }


    return (<div className="App">

        <div id="Durability">Durability: {durability}</div>
        <div id="GameBoard">
            {popup}

            <div id="Tools">
                <div id={`Tool${Tool.Pick}`} className={"Tool " + (tool === Tool.Pick ? '' : 'InactiveTool')} style={{backgroundImage: `url(/img/pick.png)`}}
                     onClick={() => setTool(Tool.Pick)}></div>
                <div id={`Tool${Tool.Hammer}`} className={"Tool " + (tool === Tool.Hammer ? '' : 'InactiveTool')} style={{backgroundImage: `url(/img/hammer.png)`}}
                     onClick={() => setTool(Tool.Hammer)}></div>
                <div id='Reset' style={{backgroundImage: `url(/img/reset.png)`}} onClick={() => resetBoard()}></div>
            </div>

            <div id="Tiles">
                {board.map((row, y) => row.map((tile: number, x: number) => DiggingTile({
                    x,
                    y,
                    tile,
                    rewardTile: rewardState.rewardBoard[y][x],
                    digOnClick: () => dig(x, y)
                })))}
            </div>

            <div id={"Dinosaur"} style={{backgroundImage: `url(/img/dino.png)`}}></div>
        </div>

    </div>);
}

function DinosaurPopup({message}: { message: string }) {
    return (<div id="DinosaurPopup">
        <div id="DinosaurPopupImage" style={{backgroundImage: `url(/img/mom.png)`}}></div>
        <div id="DinosaurPopupText">{message}</div>
    </div>)
}

function DiggingTile({x, y, tile, rewardTile, digOnClick}: {
    x: number,
    y: number,
    tile: number,
    rewardTile: any,
    digOnClick: () => void
}) {

    const tile_style = {} as React.CSSProperties;
    tile_style["backgroundImage"] = ''

    if (tile !== 0) {
        tile_style["backgroundImage"] += `url(/img/tiles/${tile}.png),`
    }

    if (rewardTile !== null) {
        tile_style["backgroundImage"] += `url(/img/${rewardTile}),`
    }

    tile_style["backgroundImage"] += `url(/img/tiles/0.png)`


    return (<div className="DiggingTile" key={`${x},${y}`} onClick={digOnClick} style={tile_style}></div>)

}


export default DiggingGame;
